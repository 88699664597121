import React, {useState, useCallback} from 'react'
import Header from "../components/Header/Header";
import NavbarMenu from '../components/NavbarMenu/NavbarMenu';
import Footer from '../components/Footer/Footer';
import {  Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Galleries from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./Photos";
import ScrollToTop from '../components/ScrollToTop'
const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <ScrollToTop />
      <Header />
      <NavbarMenu />
      
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li>Gallery</li>
        </div>
      </ul>
    <div className='heading '>
      <h1>Gallery</h1>
    </div>
    <div className='d-flex justify-content-center  mt-3 mb-3'>
      <Link to="/gallery" ><button className='btn bg-dark text-white' >Gallery</button></Link>
      <Link to="/smile-gallery" ><button className='btn ms-2 bg-light btn-outline-secondary text-dark' >Smile Gallery</button></Link>
    </div>
    <Container >
    <Galleries photos={photos} onClick={openLightbox} />
    </Container>
    <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Footer />
    </div>
  )
}

export default Gallery;