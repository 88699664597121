import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Dentalimp = () => {
  return (
    <div>

      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Dental Implants</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Dental Implants</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                    <h2>DENTAL IMPLANTS</h2>
                    <h3>Missing Single Tooth</h3>
                    <p>The single tooth implant restoration consists of three parts - the implant post which
replaces the tooth root, the abutment which will support the crown and the
prosthetic crown itself. The crown is either cemented onto the abutment or held in
place with a screw.</p>
<div className='img-box'>
<img src="/servicesicons/image-009.jpg" alt="" className="img-fluid" />
</div>
<h3>Multiple Missing Teeth
</h3>
<p>If multiple teeth are missing in different parts of the mouth, several single tooth
implants are good replacement restorations.</p>
<div className='img-box'>
<img src="/servicesicons/image-005.jpg" alt="" className="img-fluid" />
</div>
<h3>Missing All Teeth
</h3>
<p>When all teeth are missing or in a condition that all teeth need to be replaced, there
is a need for full arch implant reconstructions</p>
<div className='img-box'>
<img src="/servicesicons/image-012.jpg" alt="" className="img-fluid" />
</div>
<p>All-on-4 technology</p>
<h4>JUST FOUR IMPLANTS BUT COUNTLESS BENEFITS</h4>
<p>It is a minimally invasive solution with a fixed full-arch restoration for high patient
satisfaction</p>
<div className='img-box'>
  <Row>
    <Col xs={6}>
<img src="/servicesicons/image-013.jpg" alt="" className="img-fluid"/>
</Col>
<Col xs={6}>
<img src="/servicesicons/image-014.jpg" alt="" className='ms-1 img-fluid' />
</Col>
</Row>
</div>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
        <Footer />
    </div>
  )
}

export default Dentalimp