import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const General = () => {
  return (
    <div>
      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>General Dentistry</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>General Dentistry</h1>
    </div>
      <Container className='item-container'>
        <Row>
          <Col md={8}>
        <h2>GENERAL DENTAL SERVICES</h2>
        <img src='/image/gen.webp' alt="" className='img-fluid' />
        <p>General dentistry focuses on the health of your teeth and mouth and it is importantfor the early detection of any problems.</p>
        <p>The comprehensive examination will include:-</p>
        <p><span>Digital X-rays -</span> Those low dose images are essential to show us the anatomy ofthe teeth, and to detect any decay or any other abnormalities.</p>
        <p><span>Assessment of periodontal (Gum) condition </span> </p>
        <p><span>Professional Dental Cleaning -</span> The gums, bone and tissuess rounding all the teeth are assessed for any potential periodontal disease.</p>
        <p><span>Detection of any cavities or tooth decay –</span> every single surface of all the teeth in the mouth is examined for any potential cavities or decay.</p>
        <p><span>Assessment of existing restorations – </span> The examining dentist will assess the current condition of all existing fillings, restorations, dentures or crowns in the patient’smouth.</p>
        
        {/* <p><span></span> </p> */}
        </Col>
        <Col md={4} >
        <ItemsMenu />

        </Col>
       </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default General;