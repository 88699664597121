import React from 'react'
import Header from '../components/Header/Header';
import NavbarMenu from '../components/NavbarMenu/NavbarMenu';
import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';
import HeroCards from '../components/HeroCards/HeroCards';
import Services from '../components/Services/Services';
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs';
import AboutDoctor from '../components/AboutDoctors/AboutDoctor';
import Testimonial from '../components/Testimonial/Testimonial';

const Home = () => {


  return (
    <div>
        <Header />
        <NavbarMenu />
        <Hero />
        <HeroCards />
        <Services />
        <AboutDoctor />
        <WhyChooseUs />
        <Testimonial />
        <Footer />
    </div>
  )
}

export default Home