import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import {  toast } from 'react-toastify'

const Footer = () => {

    const Subscribe = (e)=>{
        e.preventDefault();
    
        toast.success("Thank For subscribe");
    }
  return (
    
      <footer className="footer-section">
    
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-map-marker-alt"></i>
                            <div className="cta-text">
                                <h4>Find us</h4>
                                <span>1, Ground Floor,
                                    East End Enclave, <br></br>
                                    Opp. Sai Temple, Nirman Vihar,<br></br>
                                    New Delhi - 110092</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-phone"></i>
                            <div className="cta-text">
                                <h4>Call us</h4>
                                <span>+91-88-606-506-89</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="far fa-envelope-open"></i>
                            <div className="cta-text">
                                <h4>Mail us</h4>
                                <span>delhidentalstudio.com@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href="index.html"><img src="/image/logo.png" className="img-fluid" alt="logo"  /></a>
                            </div>
                            <div className="footer-text">
                                <p>We are a fully accredited dental practicioner, with an emphasis on the best clinical practice and hygiene. <Link to="/about-us">Read More</Link> </p>
                            </div>
                            <div className="footer-social-icon">
                                <span>Follow us</span>
                                <a href="https://facebook.com/delhidentalstudio"><i className="fab fa-facebook-f facebook-bg"></i></a>
                                {/* <a href="twitter.com/delhidentalstudio"><i className="fab fa-twitter twitter-bg"></i></a> */}
                                <a href="https://www.instagram.com/delhi_dental_studio/"><i className="fab fa-instagram instgram-bg"></i></a>
                                {/* <a href="youtubedelhidentalstudio"><i className="fab fa-youtube google-bg"></i></a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading ">
                                <h3>Useful Links</h3>
                            </div>
                            <ul >
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/Services">services</Link></li>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><Link to="/contact-us">Contact</Link></li>
                                <li><Link to="/smile-gallery">Smile Gallery</Link></li>
                          

                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Subscribe</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div className="subscribe-form">
                                <form onSubmit={Subscribe}>
                                    <input type="email" placeholder="Email Address" required/>
                                    <button><i className="fab fa-telegram-plane"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container py-2">
                <div className="row">
                    <div className=" col-lg-6 text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2018, All Right Reserved <a href="https://www.delhidentalstudio.com/" target="_blank" rel="noopener noreferrer">Delhi Dental Studio</a></p>
                        </div>
                    </div>
                    <div className="col-lg-6  ">
                        <div className="footer-menu-text">
                        <p>Developed and Maintained <a href="https://alternativesolution.in" target="_blank" rel="noopener noreferrer">AGSPL</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    
  )
}

export default Footer