import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Aboutdoc.css'
const AboutDoctor = () => {
  return (
    <div>
      <Container fluid className='doctors'>
        <Row>
        <Col md={3}>
          <div className="box">

          </div>
            <img src="/image/doc1.png" alt="" className='mb-3 doc1' />
          </Col>
          <Col md={5}>
            <p className='headt'>About Doctor</p>
            <h1>Committed To <span>Dental Excellence</span></h1>
            <p>
            We are a fully accredited dental practicioner, with an emphasis on the best clinical practice and hygiene. We offer a state of the art to cater you and your family in the areas of Crown and bridges, Cosmetic Dentistry, Complete Gum Care, Dental Implants, Orthodontics, Children’s Dentistry and Comprehensive General Care
            </p>
            <p>We are committed to the best preventive dental treatment and to encourage the habit of oral hygiene and regular check up. Delhi Dental Studio is situated in East Delhi conveniently located near public transport and main roads with ample parking</p>
          </Col>
          <Col md={4}>
            <img src="/image/doc2.png" alt="" className='mb-3 doc2' />
          </Col>
          <div className='box2'></div>
        </Row>
      </Container>
    </div>
  )
}

export default AboutDoctor