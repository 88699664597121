import React from 'react'
import Header from "../components/Header/Header";
import NavbarMenu from '../components/NavbarMenu/NavbarMenu';
import Footer from '../components/Footer/Footer';
import './style.css';
import {  Col, Container, Row, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe'
const Contactus = () => {
  return (
    <div>
      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li>Contact Us</li>
        </div>
      </ul>

    <div className='heading text-center'>
      <h1>Contact Us</h1>
    </div>
    <Container fluid className='mt-5 contactus-section'>
      <Row>
        <Col md={6} className="form-col">
      
          <Card>
            <Card.Body>
              <h3 className='text-center'><i class="fas fa-paper-plane me-2"></i>Send Message</h3>
              <Row>
                <Col sm={6}>
                  <Form.Group className='mb-2'>
                    <Form.Label htmlFor='name'>Name</Form.Label>
                    <Form.Control type="text" name="name" placeholder='Name' />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                <Form.Group className='mb-2'>
                <Form.Label htmlFor='number'>Number</Form.Label>
                    <Form.Control type="tel" name="number" placeholder='Number' />
                  </Form.Group>
                </Col>
                <Form.Group className='mb-2'>
                <Form.Label htmlFor='email'>Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder='Email' />
                </Form.Group>
                <Form.Group className='mb-2'>
                <Form.Label htmlFor='subject'>Subject</Form.Label>
                    <Form.Control type="text" name="subject" placeholder='Subject' />
                </Form.Group>
                <Form.Group className='mb-2'>
                <Form.Label htmlFor='messagae'>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder='Message' />
                </Form.Group>
                <div className='d-flex justify-content-center'>
                <button className='btn btn-warning submit-btn'>Submit</button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="card-col">
          <p className='text-head'>Get in touch</p>
          <h2 className='text-title'>How can Help you?</h2>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Body className='text-center'>
                <i className="fas fa-map-marker-alt"></i>
                  <h2 className='mt-2 text-center'>Location</h2>
                  <p>1, Ground Floor,
                      East End Enclave, <br></br>
                      Opp. Sai Temple, Nirman Vihar,<br></br>
                      New Delhi - 110092</p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Body className='text-center'>
                <i className="fas fa-envelope"></i>
                  <h2 className='mt-2 text-center'>Email</h2>
                  <p>delhidentalstudio.com@gmail.com</p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Body className='text-center'>
                  <i className='fas fa-clock' />
                  <h2 className='mt-2 text-center'>Office Hours</h2>
                  <p>Monday - Saturday <br></br> 10:00 AM - 01:00 PM
<br></br>05:00 PM - 08:00 PM</p>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <Card.Body className='text-center'>
                <i className="fas fa-phone-alt"></i>
                  <h2 className='mt-2 text-center'>Let's Talk</h2>
                  <p>+91 8860650689<br></br></p>
                
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
    <div className="map-heading mt-4 mb-4 text-center">
      <h1>Find us on map</h1>
    </div>
    <Container fluid>
    <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28011.762058329114!2d77.27784643065799!3d28.64563494415594!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe102f3e29ffed885!2sDelhi%20Dental%20Studio!5e0!3m2!1sen!2sin!4v1664371893969!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
        width="100%"
        height="350px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
    </Container>
      <Footer />
    </div>
  )
}

export default Contactus