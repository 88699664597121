import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Kids = () => {
  return (
    <div>
    <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Children Dentistry</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Children Dentistry</h1>
    </div>
    <Container className='item-container'>
        <Row>
          <Col md={8}>
        <h2>CHILDREN DENTISTRY</h2>
        <h3>PIT AND FISSURE SEALANT</h3>
        <p>Prevention is the soul of all treatment and care. With little care and simple timely
procedures you can keep your mouth free from cavities or decay throughout your
life. Pit and Fissure sealants are an ideal and important solution for preventing you
from undergoing the dental pain, discomfort and complicated treatment procedures.</p>
        {/* <p><span></span> </p> */}
        <div className='img-box'>
<img src="/servicesicons/image-023.jpg" alt="" className='img-fluid' />
</div>
<h3>KIDS ROOT CANAL TREATMENT</h3>
<p>Young children can lose primary (baby) teeth and even immature permanent teeth
when the pulp, the living tissue inside a tooth, becomes infected</p>
<h3>FLUORIDE TREATMENT</h3>
<p>Fluoride Application by gels/foam is a highly concentrated form of fluoride which
is applied to the tooth's surface, by a dentist, as a type of topical fluoride therapy.</p>
<div className='img-box'>
<img src="/servicesicons/image-024.jpg" alt="" className='img-fluid' />
</div>
        </Col>
        <Col md={4} >
        <ItemsMenu />

        </Col>
       </Row>
      </Container>
    <Footer />
    </div>
  )
}

export default Kids