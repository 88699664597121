import React from 'react'
import { Container, Row, Col, Image, Card } from 'react-bootstrap'
import {GrCertificate} from 'react-icons/gr'
import {GiLoveInjection} from 'react-icons/gi'
import {MdOutlineSupportAgent} from 'react-icons/md'
import {FaUserMd} from 'react-icons/fa'
import './Why.css'
const WhyChooseUs = () => {
  return (
    <div className='why'>
        <div className='box'></div>
        <div className='box1'></div>
        <div className='box2'></div>
      <Container className=' why-container' fluid>

        <Row>
          <Col md={5}>
            <p className='head fw-bold'>Why Choose Us?</p>
            <h1>Caring your family’s dental needs.</h1>
            <p>Dental Implants India is the best destination to save without compromising on
quality. </p>
          
            <Image fluid src="/image/hero.jpg" style={{height:"300px", width:"100%", borderRadius:"5px"}}  />
          </Col>
          <Col md={7}>
            <Row>
              <Col md={6}>
                <Card>
                <GrCertificate size="4em" />
                  <Card.Body>
                  <Card.Title>Certified</Card.Title>
                  <Card.Text>We are committed to the best preventive dental treatment and to encourage the
habit of oral hygiene and regular check up. </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                <GiLoveInjection size="4em" />
                  <Card.Body>
                  <Card.Title>Facilities</Card.Title>
                  <Card.Text>Crown and bridges, Cosmetic Dentistry, Complete Gum Care, Dental Implants, Orthodontics, Children’s Dentistry</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} >
                <Card>
                <FaUserMd size="4em" />
                  <Card.Body>
                  <Card.Title>Leading doctors</Card.Title>
                  <Card.Text>We provide organized and appropriate settings to promote dental health care facilities.We have efficient doctors and staff.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} >
              <Card>
                  <MdOutlineSupportAgent size="4em" />
                  <Card.Body>
                  <Card.Title>Support</Card.Title>
                  <Card.Text>Provide Call support, You can book any time with our website and whatsapp chat supports are also available.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyChooseUs