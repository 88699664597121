import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import ItemsMenu from './ItemsMenu';
import "./items.css";
const Endodontic = () => {
  return (
    <div>

      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Endodontics Treatment</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Endodontics Treatment</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                    <h2>ENDODONTIC TREATMENT</h2>
                    <h3>ROOT CANAL TREATMENT</h3>
                    <p>Root canal or endodontic treatment has helped save millions of teeth around
the world.</p>
<div className='img-box'>
<img src="/servicesicons/image-034.jpg" alt="" className='img-fluid ' />
<img src="/servicesicons/image-035.jpg" alt="" className='img-fluid ' />
</div>
<p>Root canal treatment is the removal of the tooth’s pulp, a small, thread-like
tissue in the center of the tooth. Once the damaged, diseased or dead pulp is
removed, the remaining space is cleaned, shaped and filled. This procedure
seals off the root canal.</p>
<p>The most common causes of pulp damage or death are:
</p>
<ul>
    <li>A cracked tooth
</li>
    <li>A deep cavity
</li>
    <li>An injury to a tooth, such as a severe knock to the tooth, either recent
or in the past</li>
    
</ul>
<div className='img-box'>
<img src="/servicesicons/image-036.png" alt="" className='img-fluid ' />
</div>
<h3>ENDODONTIC SURGERY</h3>
<p>In a few cases, root canal (nonsurgical endodontic) treatment alone cannot
save the tooth. In such a case, your dentist may recommend surgery.
The most common Endodontic surgery is root end resection with root end
filing also known as 'apico' or apicoectomy and retrofill.
</p>
<div className='img-box'>
<img src="/servicesicons/endo.PNG" alt=""  className='img-fluid ' />
</div>

                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default Endodontic