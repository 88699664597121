import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Cosmetics = () => {
  return (
    <div>
      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Cosmetics Dentistry</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Cosmetics Dentistry</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                  <h2>COSMETIC DENTISTRY</h2>
                  <p>Cosmetic dentistry is dedicated to giving you the pearly whites that you deserve.</p>
                  <h3>PORCELAIN VENEERS</h3>
                  <p>Porcelain veneers are thin shells of tooth-colored materials, made to cover the front
surface of teeth to improve their appearance. They provide a natural tooth
appearance. Porcelain veneers are stain resistant</p>
<h3>USED FOR</h3>
<p>Discoloured, worn down, chipped or broken, misaligned, uneven, or irregularly
shaped, teeth with gaps between them (to close the space between these teeth)
</p>
<div className='img-box'>
<img src="/servicesicons/image-001.jpg" alt="" />
</div>
<h3>Composite Resin Veneers</h3>
<p>Veneers can also be made of Composite Resin. The advantages of Composite resin
veneers are that they can be completed in only one visit. Composite Veneers are
also cheaper than Porcelain Veneers</p>
<h3>TEETH WHITENING</h3>
<p>Teeth whitening is a simple procedure used to lighten the colour of your teeth to a
desired shade.</p>
<p>A variety of methods are available: home whitening involves the use of a clear gum
shield into which a whitening agent is applied and then worn overnight. The other
methods involve the application of a gel and laser treatment.</p>
<div className='img-box'>
<img src="/servicesicons/image-002.jpg" alt="" className="img-fluid" />
</div>
<h3>TREATMENT OF CRACKED TOOTH
</h3>
<p>The dentists simple use dental bonding to repair small cracks on tooth. Besides,
they use tooth-colored filling material to fill the missing portion. The dentists shape
the material to give it a real look.
</p>
<div className='img-box'>
<img src="/servicesicons/image-003.jpg" alt="" className="img-fluid" />
</div>
<h3>PORCELAIN CROWNS
</h3>
<p>Porcelain Crowns are one of the strongest and most aesthetic dental restorations.
They are used to reinforce and maintain the integrity of the existing tooth as well as
enhance the cosmetic appearance of the tooth.</p>
<div className='img-box'>
<img src="/servicesicons/image-004.jpg" alt="" className="img-fluid" />
</div>
<h3>FIXED BRIDGES</h3>
<p>Fixed Bridges are one of the ways we can replace missing teeth. Provided that
there is a tooth on either side of the gap, a bridge can be placed from one tooth to
the other to cover the gap left by a missing tooth or several teeth. The two teeth on
either side of the gap serve as anchor teeth for the bridge.
</p>
<div className='img-box'>
<img src="/servicesicons/image-005.jpg" alt="" className="img-fluid" />
</div>
<h3>GUMMY SMILE TREATMENT</h3>
<p>A Gummy Smile is where there is a excess amount of gum tissue showing
compared with actual tooth structure when a patient smiles. The teeth are usually
short in appearance.</p>
<div className='img-box'>
<img src="/servicesicons/image-006.jpg" alt="" className="img-fluid" />
</div>
<h3>SMILE MAKEOVER
</h3>
<p>Is there any defect in your tooth due to which you can have the confident and
perfect smile? Fortunately, there are several options available in dentistry that is
designed to correct the imperfections of the teeth without any pain.
</p>
<div className='img-box'>
<img src="/servicesicons/image-007.jpg" alt="" className="img-fluid" />
</div>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
        <Footer />
    </div>
  )
}

export default Cosmetics