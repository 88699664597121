import React from 'react'
import { ListGroup } from 'react-bootstrap'
import "./items.css"
import { Link, useLocation } from 'react-router-dom';

import _ from "lodash";
const ItemsMenu = () => {
    const serviceData=[
        {id:1,sttile:"General Dentistry",spara:"General dentistry focuses on the health of your teeth and mouth and it is important",duration:1000, links:"/general-dentistry"},
        {id:2,sttile:"Cosmetic Dentistry",spara:"Cosmetic dentistry is dedicated to giving you the pearly whites that you deserve.",duration:2000, links:"/cosmetic-dentistry"},
        {id:3,sttile:"Dental Implants",spara:"The single tooth implant restoration consists of three parts - the implant post which ",duration:3000, links:"/dental-implants"},
        {id:4,sttile:"Gums Treatment",spara:"Dental cleanings involve removing plaque (soft, sticky, bacteria film) and tartar " ,duration:1000, links:"/gums-treatment"},
        {id:5,sttile:"Orthodontics",spara:"Comprehensive Orthodontic Treatment for Children, Teenagers and Adult Advanced.",duration:2000, links:"/orthodontics"},
        {id:6,sttile:"Maxillofacial Surgery",spara:"Dental extraction (also referred to as exodontia) is the removal of a tooth.",duration:3000, links:"/maxillofacial-surgery"},
        {id:7,sttile:"Dental Radiography",spara:"Digital radiography is a form of X-ray imaging, where digital X-ray sensors are" ,duration:1000, links:"/dental-radiography"},
        {id:8,sttile:"Prosthesis",spara:"A crown is a dental restoration that covers or ‘caps’ a tooth to restore it to its",duration:2000, links:"/prosthesis"},
        {id:9,sttile:"Endodontics Treatment",spara:"Root canal or endodontic treatment has helped save millions of teeth.",duration:3000, links:"/endodontic-treatment"},
        {id:10,sttile:"Jaw Pain",spara:"Temporomandibular Joint disorder otherwise known as “TMD” is a very",duration:1000, links:"/jaw-pain-and-headache"},
        {id:11,sttile:"Snoring & Sleep Apnoea",spara:"Forty-five percent of normal adults snore at least occasionally and 25 percent",duration:1000, links:"/snoring-and-sleep-apnoea"},
        {id:12,sttile:"Children Dentistry",spara:"Prevention is the soul of all treatment and care. With little care and simple timely",duration:3000, links:"/children-dentistry"},
      ]
      const [searchValue, setSearchValue] = React.useState("");
      const [filteredUsers, setFilteredUsers] = React.useState(serviceData);
    
      const handleSearchFilter = (e) => {
        setSearchValue(e.target.value);
      };
    
      React.useEffect(() => {
        const timeout = setTimeout(() => {
          const filter = _.filter(serviceData, (serviceData) => {
            return _.includes(
              _.lowerCase(JSON.stringify(_.values(serviceData))),
              _.lowerCase(searchValue)
            );
          });
          setFilteredUsers(filter);
        }, 500);
        return () => clearTimeout(timeout);
      }, [searchValue]);
      const location = useLocation(); // once ready it returns the 'window.location' object
const [url, setUrl] = React.useState(null);
React.useEffect(() => {
  setUrl(location.pathname);
}, [location]);
  return (
    <div>
    <ListGroup className="border-0 rounded-0" >
    <input
        type="search"
        placeholder="Search Services..."
        value={searchValue}
        onChange={handleSearchFilter}
      />
    {_.map(filteredUsers,(serviceData)=>{return(<>
      <ListGroup.Item as={Link} to={serviceData.links} className={(url === serviceData.links ?" activate" : "")}>
        {serviceData.sttile}
      </ListGroup.Item>
      </>)})}
  
    </ListGroup>
    </div>
  )
}

export default ItemsMenu