import React from 'react'
import Header from "../components/Header/Header";
import NavbarMenu from '../components/NavbarMenu/NavbarMenu';
import Footer from '../components/Footer/Footer';
import {  Container, Row, Col, Card, Button, Image, } from 'react-bootstrap';
import AOS from 'aos';
import { Link } from 'react-router-dom';
const Services = () => {
  AOS.init();
  const serviceData=[
    {id:1,simg:"/icons/tooth-insurance-1.png",sttile:"General Dentistry",spara:"General dentistry focuses on the health of your teeth and mouth and it is important",duration:1000, links:"/general-dentistry"},
    {id:2,simg:"/icons/dental-fillings.png",sttile:"Cosmetic Dentistry",spara:"Cosmetic dentistry is dedicated to giving you the pearly whites that you deserve.",duration:2000, links:"/cosmetic-dentistry"},
    {id:3,simg:"/icons/implant.png",sttile:"Dental Implants",spara:"The single tooth implant restoration consists of three parts - the implant post which ",duration:3000, links:"/dental-implants"},
    {id:4,simg:"/icons/braces.png ",sttile:"Gums Treatment",spara:"Dental cleanings involve removing plaque (soft, sticky, bacteria film) and tartar " ,duration:1000, links:"/gums-treatment"},
    {id:5,simg:"/icons/orthodontic.png",sttile:"Orthodontics",spara:"Comprehensive Orthodontic Treatment for Children, Teenagers and Adult Advanced.",duration:2000, links:"/orthodontics"},
    {id:6,simg:"/icons/extraction.png",sttile:"Maxillofacial Surgery",spara:"Dental extraction (also referred to as exodontia) is the removal of a tooth.",duration:3000, links:"/maxillofacial-surgery"},
    {id:7,simg:"/icons/whitening.png ",sttile:"Dental Radiography",spara:"Digital radiography is a form of X-ray imaging, where digital X-ray sensors are" ,duration:1000, links:"/dental-radiography"},
    {id:8,simg:"/icons/tooth.png",sttile:"Prosthesis",spara:"A crown is a dental restoration that covers or ‘caps’ a tooth to restore it to its",duration:2000, links:"/prosthesis"},
    {id:9,simg:"/icons/root-canal.png",sttile:"Endodontics Treatment",spara:"Root canal or endodontic treatment has helped save millions of teeth.",duration:3000, links:"/endodontic-treatment"},
    {id:10,simg:"/icons/toothache.png",sttile:"Jaw Pain",spara:"Temporomandibular Joint disorder otherwise known as “TMD” is a very",duration:1000, links:"/jaw-pain-and-headache"},
    {id:11,simg:"/icons/snore.png",sttile:"Snoring & Sleep Apnoea",spara:"Forty-five percent of normal adults snore at least occasionally and 25 percent",duration:2000, links:"/snoring-and-sleep-apnoea"},
    {id:12,simg:"/icons/happy-tooth.png",sttile:"Children Dentistry",spara:"Prevention is the soul of all treatment and care. With little care and simple timely",duration:3000, links:"/children-dentistry"},
  ]

  return (
    <div>
        <Header />
        <NavbarMenu />
        
        <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li>Services</li>
        </div>
      </ul>
            <div className='heading text-center'>
            <h1>Services</h1>
            </div>
           
            <Container  className="services" style={{marginTop:"20px"}}>
                <Row>
                    <Col md={3} className="col-first">
                        <p>About Service</p>
                        <h1>Because your smile is our passion.</h1>
                    </Col>
                    <Col md={6} className="col-mid pt-5">
                        <p>Our main focus is on the health of your teeth and mouth and it is important for the early detection of any problems. Delhi Dental Studio, the hub for family dental care</p>
                        <button className='btn mb-2'><a href="#service" style={{textDecoration:"none",color:"#fff"}}>Discover More</a></button>
                    </Col>
                    <Col md={3} className="col-last">
                    <Card className="opening-hours rounded-0">
                  <Card.Body>
                    <h3 className='mb-3'>Opening Hours</h3>
                    <div style={{display:"table-row;"}}>
                      <div style={{display:"table-cell", verticalAlign: "middle"}}>
                    <i className="far fa-clock me-2 mb-4"></i></div>
                    <div style={{display:"table-cell", verticalAlign: "middle",wordBreak:"break-all"}}>
                    <p>Monday - Saturaday <br></br>10:00 AM - 01:00 PM</p>
                    </div>
                    </div>
                    <div style={{display:"table-row;"}}>
                      <div style={{display:"table-cell", verticalAlign: "middle"}}>
                    <i className="far fa-clock me-2 mb-4"></i></div>
                    <div style={{display:"table-cell", verticalAlign: "middle",wordBreak:"break-all"}}>
                    <p>Monday - Saturaday  <br></br>05:00 PM - 08:00 PM</p>
                    </div>
                    </div>
                    <div style={{display:"table-row;"}}>
                      <div style={{display:"table-cell", verticalAlign: "middle"}}>
                    <i className="far fa-clock me-2 mb-4"></i></div>
                    <div style={{display:"table-cell", verticalAlign: "middle",wordBreak:"break-all"}}>
                    <p>Sunady <br></br>( Closed )</p>
                    </div>
                    </div>
                  </Card.Body>
                </Card>
                    </Col>
                </Row>
            </Container>
      <Container fluid className='py-5 service-section-card' >
         
          <h1 className='text-center'>Our Procedures</h1>
          <div className='p-3'><p className='text-center'>We are committed to the best preventive dental treatment and to encourage the
habit of oral hygiene and regular check up.</p></div>
          
          <Row id="service">
          {serviceData.map((item)=>{return(<>
            <Col md={4} data-aos='fade-up' data-aos-duration={item.duration} data-aos-once='true'>
              <Card >
                <div className='d-flex justify-content-center pt-4'>
                <Image src={item.simg}  height="51" width="51" />
                </div>
                <Card.Body >
                  <h3 className='text-center'>{item.sttile}</h3>
                  <p className='text-center'>{item.spara.slice(0,100)}... </p>
                  <div className='d-flex justify-content-center'>
                  <Button variant="outline-warning rounded-0" as={Link} to={item.links}>Read more</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            </>)})}
          </Row>
      </Container>
      
        <Footer />
    </div>
  )
}

export default Services