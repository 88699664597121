import React, {useState, useCallback} from 'react'
import Header from "../components/Header/Header";
import NavbarMenu from '../components/NavbarMenu/NavbarMenu';
import Footer from '../components/Footer/Footer';
import './style.css';
import {  Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Galleries from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
const SmileGallery = () => {
    const smile = [
        {
            src: "/smile/21.jpg",
            width: 4,
            height: 3
          },
          {
            src: "/smile/1.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/2.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/3.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/18.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/4.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/5.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/6.jpg",
            width: 3,
            height: 4
          },
                    {
            src: "/smile/7.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/8.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/9.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/10.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/11.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/12.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/13.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/14.jpg",
            width: 3,
            height: 4
          },
          {
            src: "/smile/15.png",
            width: 3,
            height: 4
          },
          {
            src: "/smile/16.png",
            width: 3,
            height: 4
          },
          {
            src: "/smile/17.png",
            width: 3,
            height: 4
          },
          {
            src: "/smile/19.png",
            width: 3,
            height: 4
          },
          {
            src: "/smile/20.png",
            width: 3,
            height: 4
          },


    ]
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { smile , index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
  return (
    <div style={{backgroundImage:"url(https://static.vecteezy.com/system/resources/previews/006/405/705/original/abstract-geometric-white-and-gray-gradient-background-modern-and-minimal-white-elements-background-illustration-free-vector.jpg)"}}>
     <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li>SMILE GALLERY</li>
        </div>
      </ul>

    <div className='heading text-center'>
      <h1>Smile Gallery</h1>
    </div>
    <div className='d-flex justify-content-center  mt-3 mb-3'>
    <Link to="/smile-gallery" ><button className='btn  bg-dark text-white' >Smile Gallery</button></Link>
      <Link to="/gallery" ><button className='btn ms-2 bg-light btn-outline-secondary text-dark' >Gallery</button></Link>

    </div>

    <Container >
    <Galleries photos={smile} onClick={openLightbox} />
    </Container>
        <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={smile.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    <Footer />

    </div>
  )
}

export default SmileGallery