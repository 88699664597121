import React from 'react'
import "./notfound.css";
import { Link } from 'react-router-dom';
const Notfound = () => {
  return (
    <div>
      
        <div className="main-404">
            <div className='d-flex justify-content-center'>
            <h1 className='mt-3'>4</h1><img src="/image/broken-tooth.png" /><h1 className='ms-3'>4</h1>
            </div>
           
        <p>It looks like you're lost. That's a trouble?</p>
        <div className='d-flex justify-content-center'>
        <button className='btn btn-warning'><Link to="/" style={{color:"#fff",textShadow:"2px 0 3px black", textDecoration:"none"}}>Go back <i className='fas fa-home'/></Link></button>
        </div>
        
        </div>
    </div>
  )
}

export default Notfound