import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Stronging = () => {
  return (
    <div>
      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Snroring & Sleep Apnoea</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Snroring & Sleep Apnoea</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                  <h2>SNORING AND SLEEP APNOEA</h2>
                  <p>Cosmetic dentistry is dedicated to giving you the pearly whites that you deserve.</p>
                  <div className='img-box'>
<img src="/image/snoring.jpg" alt=""  className='img-fluid ' />
</div>
                  <h3>PORCELAIN VENEERS</h3>
                  <p>Forty-five percent of normal adults snore at least occasionally and 25 percent
are habitual snorers. Snoring may be an indication of obstructed breathing
and should not be taken lightly.
Snoring may be a sign of a more serious condition known as obstructive
sleep apnea (OSA). OSA is characterized by multiple episodes of breathing
pauses greater than 10 seconds at a time, due to upper airway narrowing or
collapse.
</p>

<p><strong>Solution</strong></p>
<p>The appliance known as the Somnosore® (to treat snoring), or the
Somnomed® (to treat Sleep Apnoea), a custom-fit oral appliance, which
repositions the lower jaw forward, may also be considered for certain
patients with snoring/OSA.</p>
<p><big>SOMNOSORE®</big></p>
<div className='img-box'>
<img src="/servicesicons/image-039.jpg" alt=""  className='img-fluid ' />
</div>
<p><big>SOMNOMED®</big></p>
<div className='img-box'>
<img src="/servicesicons/snore.PNG" alt=""  className='img-fluid ' />
</div>
                </Col>
                <Col md={4}>
                  <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default Stronging