import React from 'react'
import Header from "../components/Header/Header";
import NavbarMenu from '../components/NavbarMenu/NavbarMenu';
import Footer from '../components/Footer/Footer';
import { Container, Col, Row, Card , Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
const About = () => {
  function sendEmail(e){
    e.preventDefault();
    emailjs.sendForm('service_d5vqnib','template_jxdyv5i', e.target,'z5483ew5YNC4QPDSz'
    
    ).then(res=>{
        console.log(res);
        toast.success("Thanks for booking Appointment Please check your mail.")
    }).catch(err=> console.log)
}
  return (
    <div>

    <Header />
      <NavbarMenu />
<ToastContainer  position='top-center'/>
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li>About Us</li>
        </div>
      </ul>
    <div className='heading '>
      <h1>About Us</h1>
    </div>
    <Container fluid className="about-clinic">
        <Row>
            <Col md={6} className="first-col">
                <Card><Card.Img src="/image/lDSC_0551.JPG"/></Card>
              
            </Col>
            <Col md={6} className="second-col pt-5">
                    <p className='heading-title'>About Clinic</p>
                    <h1>A leading visionary in <br></br>dental care.</h1>
                    <p>We are a fully accredited dental practice, with an emphasis on best clinical practice and hygiene. We offer a state of the art to cater you and your family in the areas of Crown and bridges, Cosmetic Dentistry, Complete Gum Care, Dental Implants, Orthodontics, Children’s Dentistry and Comprehensive General Care.</p>
                    <p>Delhi Dental Studio is situated in East Delhi conveniently located near public transport and main roads with ample parking.</p>
            </Col>
        </Row>
    </Container>
    <Container className='about-health' fluid>
        <img src="/image/reception.JPG" alt="" className='img-fluid' />
        <Card>
            <Card.Body>
                <p className='heading-title'>Our Value</p>
                <h1>Caring for all your family’s <br></br>dental needs.</h1>
                <p> The safety of  our patients and  the environment is our priority .</p>
                <Row className="mt-5">
                    <Col md={6}>
                        <Row>
                            <Col sm={2} className="icon">
                            <i className="fas fa-stethoscope"></i>
                            </Col>
                            <Col sm={10} className="sentence">
                                <h2>Mission</h2>
                                <p>To provide every patient a dental experience that exceeds their expectations while building a relationship based on trust & exceptional customer service.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                    <Row>
                            <Col sm={2} className="icon">
                            <i className="fas fa-tooth"></i>
                            </Col>
                            <Col sm={10} className="sentence">
                                <h2>Vision</h2>
                                <p>We believe that excellence is an attitude to everything we do and not a goal. We are committed to achieving excellence in everything we do.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Container>
    <Container fluid className="doctor-section">
      <div className="why">
    <div className='box'></div>
        <div className='box1'></div>
        <div className='box2'></div>
        </div>
        <Row>
            <Col md={8} >
            
                <Row>
                    <Col md={4}>
                      <Card>
                        <Card.Img src="https://lh3.googleusercontent.com/p/AF1QipPRuy6UxOKCDjIMnyY-_o2sNuua3YHrcoIcfrBb=s2732-w2732-h1314" />
                      </Card>
                        
                    </Col>
                    <Col md={8}>
                      <Card><Card.Img src="/image/certificates.JPG" /></Card>
                      <Card className="d-flex"><Card.Img src="/image/delhidentalbanner.jpg"/></Card>
                  
                    </Col>

                    <Col md={8}>
                    <Card><Card.Img src="https://lh3.googleusercontent.com/p/AF1QipPc6FJi4axqdgsAyGNbY_CgQ44sjtDGOsUYRTff=s2732-w2732-h1314" /></Card>

                    </Col>
                    <Col md={4}>
                    <Card><Card.Img src="/image/IMG_20160425_211344.jpg" /></Card>
                    
                    </Col>
                </Row>
            </Col>
            <Col md={4}>
            <img src="/image/doc1.png" alt="" />
            <p className='ms-5 fw-bold'>Dr. Priyank Bhandari</p>
            <Card>
                        <Card.Img src="/image/unnamed.jpg" />
               </Card>
               
            </Col>
        </Row>
    </Container>
        <Container fluid className='mt-5 contactus-section' >
        <Row>
        <Col md={6} className="card-col-about" >
        <div className="box"></div>
        <div className="box1"></div>
        <div className="box2"></div>
        <div className="box4"></div>
           <img src="/image/doc2.png" className="img-fluid" alt="" />
           <p className='ms-5 fw-bold'>
Dr. Surpreet Bhandari</p>
            </Col>
          <Col md={6} className="form-col">
        
            <Card >
              <Card.Body>
                <h3 className='text-center'><i class="fas fa-paper-plane me-2"></i>Book Appointment</h3>
              <form onSubmit={sendEmail}>
                <Row>
                  <Col sm={6}>
                    <Form.Group className='mb-2'>
                      <Form.Label htmlFor='name'>Name</Form.Label>
                      <Form.Control type="text" name="name" placeholder='Name' required />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='number'>Number</Form.Label>
                      <Form.Control type="tel" name="mobile" placeholder='Number' required />
                    </Form.Group>
                  </Col>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='email'>Email</Form.Label>
                      <Form.Control type="email" name="email" placeholder='example@gmail.com' required />
                  </Form.Group>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='email'>Date</Form.Label>
                      <Form.Control type="date" name="apdate" placeholder='Date' required />
                  </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='time'>Time</Form.Label>
                      <Form.Control type="time" name="time" placeholder='time' required />
                  </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                <Form.Label htmlFor='Treatment' className='ms-3'>Treatment</Form.Label>
                <Form.Select placeholder='Treatment' name="treatment" required>
                <option value="Crown and bridges">Crown and bridges</option>
                            <option value=" Cosmetic Dentistry"> Cosmetic Dentistry</option>
                            <option value=" Complete Gum Care"> Complete Gum Care</option>
                            <option value="Dental Implants">Dental Implants</option>
                            <option value="Orthodontics">Orthodontics</option>
                            <option value="Children’s Dentistry">Children’s Dentistry</option>
                            <option value="Tooth Extraction">Tooth Extraction</option>
                            <option value="Teeth Braces">Teeth Braces</option>
                            <option value="Dental Fillings">Dental Fillings</option>
                            <option value="Teeth Whitening">Teeth Whitening</option>
                            </Form.Select>
                </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='doctor' className='ms-3'>Select Doctor</Form.Label>
                  <Form.Select name="doctor" id="doctor">
                    <option value="not selected">Select</option>
                    <option value="Dr. Priyank Bhandari">Dr. Priyank Bhandari</option>
                    <option value="Dr. Surpreet Bhandari">Dr. Surpreet Bhandari</option>
                  </Form.Select>
                  </Form.Group>
                  </Col>

    
                  <div className='d-flex justify-content-center'>
                  <button className='btn btn-warning submit-btn'>Submit</button>
                  </div>
                </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
     
        </Row>
  
      </Container>
    <Footer />
    </div>
  )
}

export default About