import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Jaw = () => {
  return (
    <div>

      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Jaw Pain</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Jaw Pain</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                  <h2>JAW PAIN AND HEADACHES</h2>
                  <p>Temporomandibular Joint disorder otherwise known as “TMD” is a very
common condition affecting many individuals. TMD sufferers usually report
excessive clenching or grinding of their teeth, headaches, and jaw pain.</p>
<p><strong>Bruxism
</strong></p>
<p>When a patient reports repeated clenching or teeth grinding we generally
identify those symptoms as Bruxism. This can occur during the day but in
most cases, patients report night-time bruxism. These long term actions can
have a detrimental effect on both the teeth and the Temporomandibular Joint
(TMJ) also known as the Jaw joint.
</p>
<p>We provide removable custom made acrylic splints or night guards which are
carefully placed over the top or bottom teeth. This acts to disclude the teeth
thus protecting them from wear and at the same time provides relief to the
jaw joint (TMJ) throughout the whole night.
</p>
<div className='img-box'>
<img src="/servicesicons/image-038.jpg" alt="" className='img-fluid' />
</div>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default Jaw