import React,{useState} from 'react'
import { Parallax} from "react-parallax";
import './Hero.css'
import AOS from 'aos';
const Hero = () => {
  const styles = {
    fontFamily: "sans-serif",
    textAlign: "left",
    

  };
  const [margintop, setmargintop]=useState(false);
  const changeMargin = () => {
    if(window.scrollY >= 90) {
      setmargintop(true)
    } else {
      setmargintop(false)
    }
}
window.addEventListener("scroll", changeMargin)
  // const insideStyles = {
  //   padding: 20,
  //   position: "absolute",
  //   top: "50%",
  //   fontWeight:"700",
  //   color:"#fff",
  //   fontSize:"50px",
  //   left: "50%",
  //   textShadow:"0px 0px 3px black",
  //   transform: "translate(-100%,-60%)"
  // };
  AOS.init();
  return (
    <div className={margintop?"hero-bg":""}>
  <div style={styles}>
    <Parallax bgImage="/image/hero.jpg" ur={{ min: -1, max: 3 }}>
      
      <div style={{ height: 600 }}>
        <p class="welcome-text" data-aos='fade-up' data-aos-duration='1000' data-aos-once='true'>Welcome To</p>
        <div className='Hero-text' data-aos='fade-up' data-aos-duration='2000' data-aos-once='true'>Delhi Dental Studio</div>
        <div className='hero-p' data-aos='fade-up' data-aos-duration='3000' data-aos-once='true'> the hub for family <br></br>dental care</div>
        <button className='hero-btn' data-aos='fade-up' data-aos-duration='3000' data-aos-once='true' ><a href="#services" style={{textDecoration:"none",color:"#fff"}}>See More </a><i className="fas fa-angle-right" /></button>
      </div>
    </Parallax>
    </div>
    </div>
  )
}

export default Hero