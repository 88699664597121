import React from 'react'
import { Card,Container,Row,Col, Form} from 'react-bootstrap'
import './HeroCard.css';
import AOS from 'aos';
import emailjs from 'emailjs-com'
import { ToastContainer, toast } from 'react-toastify';
function sendEmail(e){
  e.preventDefault();
  emailjs.sendForm('service_d5vqnib','template_jxdyv5i', e.target,'z5483ew5YNC4QPDSz'
  
  ).then(res=>{
    toast.success("Thanks for booking Appointment")
      console.log(res);
  }).catch(err=> console.log)
}
const HeroCards = () => {
  AOS.init();
  return (
    <div>
      <ToastContainer position='top-center' />
        <Container className="herocard-section">
            <Row>

              <Col md={4}>
                <Card className="opening-hours" >
                  <Card.Body>
                    <h1 className='mb-3'>Opening Hours</h1>
                    <div style={{display:"table-row;"}}>
                      <div style={{display:"table-cell", verticalAlign: "middle"}}>
                    <i className="far fa-clock me-2 mb-4"></i></div>
                    <div style={{display:"table-cell", verticalAlign: "middle",wordBreak:"break-all"}}>
                    <p>Monday - Saturday <br></br>10:00 AM - 01:00 PM</p>
                    </div>
                    </div>
                    <div style={{display:"table-row;"}}>
                      <div style={{display:"table-cell", verticalAlign: "middle"}}>
                    <i className="far fa-clock me-2 mb-4"></i></div>
                    <div style={{display:"table-cell", verticalAlign: "middle",wordBreak:"break-all"}}>
                    <p>Monday - Saturday <br></br>05:00 PM - 08:00 PM</p>
                    </div>
                    </div>
                    <div style={{display:"table-row;"}}>
                      <div style={{display:"table-cell", verticalAlign: "middle"}}>
                    <i className="far fa-clock me-2 mb-4"></i></div>
                    <div style={{display:"table-cell", verticalAlign: "middle",wordBreak:"break-all"}}>
                    <p>Sunady <br></br>( Closed )</p>
                    </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="detail" >
                  <Card.Body>
                    <h1>Emergency?</h1>
                    <h4>Please Call Us at</h4>
                    <h1>+918860650689</h1>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card className='booking' >
                  <Card.Body>
                    <Card.Title>Book Appointment?</Card.Title>
                    <p>Book your appointment today. </p>
                    <Form onSubmit={sendEmail}>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-1'>
                            <Form.Control type="text" placeholder='Name' name="name" required />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-1'>
                            <Form.Control type="tel" placeholder='Mobile' name="mobile"/>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-1'>
                            <Form.Control type="email" placeholder='Email' name="email" required />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-1'>
                            <Form.Control type="Date" name="apdate" />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-1'>
                            <Form.Control type="time" placeholder='Time' name="time" />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          
                          <Form.Group className='mb-1'>
                            <Form.Select placeholder='Treatment' name="doctor" required>
                            <option value="doctor">Doctor</option>
                            <option value="Dr. Priyank Bhandari">Dr. Priyank Bhandari</option>
                           <option value="Dr. Surpreet Bhandari">Dr. Surpreet Bhandari</option>
                  
                            </Form.Select>
                          </Form.Group>
                       
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-1'>
                            <Form.Select placeholder='Treatment' name="treatment">
                            <option value="Crown and bridges">Crown and bridges</option>
                            <option value=" Cosmetic Dentistry"> Cosmetic Dentistry</option>
                            <option value=" Complete Gum Care"> Complete Gum Care</option>
                            <option value="Dental Implants">Dental Implants</option>
                            <option value="Orthodontics">Orthodontics</option>
                            <option value="Children’s Dentistry">Children’s Dentistry</option>
                            <option value="Tooth Extraction">Tooth Extraction</option>
                            <option value="Teeth Braces">Teeth Braces</option>
                            <option value="Dental Fillings">Dental Fillings</option>
                            <option value="Teeth Whitening">Teeth Whitening</option>
                  
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <button className='book-btn'>Book</button>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        </Container>
    </div>
  )
}

export default HeroCards