import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
const Gumstreat = () => {
  return (
    <div>

      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Gums Treatment</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Gums Treatment</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                    <h2>GUMS TREATMENT</h2>
                    <h3>Full Mouth Scaling & Polishing</h3>
                    <p>Dental cleanings involve removing plaque (soft, sticky, bacteria film) and tartar
(calculus) deposits that have built up on the teeth over time. An ultrasonic
instrument, which uses tickling vibrations to knock larger pieces of tartar loose, is
used for this procedure. It also sprays a cooling mist of water while it works to
wash away debris and keep the area at a proper temperature.
</p>
<p>It is recommended to visit your dentist every 6 months for then regular check-up &
full mouth scaling & polishing.</p>
<h3>Deep Cleaning</h3>
<p>The bacteria irritate the gums, which means that they are inflamed hence tend to
bleed easily. You may notice this if you are brushing your teeth, or eating, and
sometimes your gums may bleed a bit.
</p>
<p>Gum disease can break down the support (bone) structures of the teeth, so that
eventually, they will become loose. The problem is that until it gets quite severe,
the person often has no symptoms. Sadly, the damage to the support structures of
the teeth is irreversible
</p>
<div className='img-box'>
<img src="/servicesicons/image-016.jpg" alt="" className='img-fluid' />
</div>
<h3>GUMS SURGERY</h3>
<p>Flap surgery/pocket reduction surgery- During this procedure the gums are lifted
back and the tarter is removed.</p>
<div className='img-box'>
<img src="/servicesicons/image-017.jpg" alt="" className='img-fluid' />
</div>
<p>Bone grafts- This procedure involves using fragments of your own bone, synthetic
bone, or donated bone to replace bone destroyed by gum disease. New technology,
called tissue engineering, encourages your own body to regenerate bone and tissue
at an accelerated rate.
</p>
<p>Soft tissue grafts-This procedure reinforces thin gums or fills in places where gums
have receded.</p>
<p>Guided tissue regeneration- Performed when the bone supporting your teeth has
been destroyed, this procedure stimulates bone and gum tissue growth.
</p>
<h3>SOCKET PRESERVATION THERAPY</h3>
<p>It is a procedure in which graft material or scaffold is placed in the socket of an
extracted tooth at the time of extraction to preserve the alveolar ridge</p>
<div className='img-box'>
<img src="/servicesicons/image-018.jpg" alt="" className='img-fluid' />
</div>
<h3>MOBILE TEETH SOLUTION WITH SPLINTING</h3>
<p>Trauma to the teeth can be transmitted to the supporting structures, which get
damaged. This can cause mobility of the teeth. Such mobile teeth may require
splinting for a specified period of time till the supporting tissues heal and the tooth
becomes stable</p>
<div className='img-box'>
<img src="/servicesicons/image-019.jpg" alt="" className='img-fluid' />
</div>
<h3>GUMS DEPIGMENTATION
</h3>
<p>The color of the Gingiva or the gum tissue is pink in color, but due to accumulation
of excessive melanin the patient experience ugly patches on gum tissues which is
considered to be a cosmetic dental problem. There are several reasons due to which
the gum tissue has patches.
</p>
<ReactCompareSlider
  itemOne={<ReactCompareSliderImage src="/servicesicons/comparebefore.PNG" srcSet="/servicesicons/comparebefore.PNG" alt="Image one" />}
  itemTwo={<ReactCompareSliderImage src="/servicesicons/compareafter.PNG" srcSet="/servicesicons/compareafter.PNG" alt="Image two" />}
/>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default Gumstreat