import React,{useState,useEffect} from 'react'
import { Navbar, Container, Nav, Modal, Card, Form, Row, Col } from 'react-bootstrap';
import './NavbarMenu.css'
import { Link, useLocation } from 'react-router-dom';
import {BsBookmarkPlus} from  'react-icons/bs'
import emailjs from 'emailjs-com'
import {  toast } from 'react-toastify';
const NavbarMenu = () => {
  const [fix,setfix]=useState(true);
  const changeSize = () => {
    if(window.scrollY <= 90) {
        setfix(true)
    } else {
        setfix(false)
    }
}
window.addEventListener("scroll", changeSize)
// const [imgsize,setimgsize]=useState(false);
// const changeImgSize =()=>{
//   if(window.scrollY <=90){
//     setimgsize(true)
//   }
//   else{
//     setimgsize(false)
//   }
// }
// window.addEventListener("scroll",changeImgSize)
const [click, setClick]=useState(false);
const handleClick = () =>setClick(!click);
const [show, setShow] = useState(false);
const CloseMobileBar = ()=>{setClick(false)}
const handleClose = () => setShow(false);
const handleShow = () => {setShow(true); setClick(false)};
const location = useLocation(); // once ready it returns the 'window.location' object
const [url, setUrl] = useState(null);
useEffect(() => {
  setUrl(location.pathname);
}, [location]);

function sendEmail(e){
  e.preventDefault();
  emailjs.sendForm('service_d5vqnib','template_jxdyv5i', e.target,'z5483ew5YNC4QPDSz'
  
  ).then(res=>{
    toast.success("Thanks for booking Appointment Please check your mail.")
      console.log(res);
  }).catch(err=> console.log)
}
// const closeMobileMenu = () =>setClick(false)
  return (
    <div>
    <Navbar collapseOnSelect expand="lg" className="NavbarMenu shadow" fixed={fix?"":"top"}>
      <Container fluid>
        <Navbar.Brand as={Link} to="/" ><img src="/image/logo.png" alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleClick} ><i className={click ? 'fas fa-times' : 'fas fa-bars' } /></Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link eventKey={1} as={Link} to="/" className={(url === "/" ?" active" : "")} onClick={CloseMobileBar}>Home</Nav.Link>
            <Nav.Link eventKey={2} as={Link} to="/about-us"  className={(url === "/about-us" ?" active about" : "about")} onClick={CloseMobileBar}>About us</Nav.Link>
            <Nav.Link eventKey={3} as={Link} to="/Services"  className={(url === "/Services" ?" active service" : "service")} onClick={CloseMobileBar}>Services</Nav.Link>
            <Nav.Link eventKey={4} as={Link} to="/contact-us" className={(url === "/contact-us" ?" active contact" : "contact")}onClick={CloseMobileBar} >contact us</Nav.Link>
            <Nav.Link eventKey={5} as={Link} to="/smile-gallery"  className={(url === "/smile-gallery" ?" active gallery" : "gallery")} onClick={CloseMobileBar}>Smile Gallery</Nav.Link>
            <Nav.Link eventKey={6} onClick={handleShow} type="button" className='btn btn-warning book-apnt-btn text-white'><BsBookmarkPlus /><span style={{color:"#fff"}}>Book Appointment</span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Modal show={show} onHide={handleClose} className='contactus-section' size="md" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="form-col" style={{padding:"0"}}>
       
        <Card style={{marginTop:"0em"}}>
            <Card.Body>
              <h3 className='text-center'><i class="fas fa-paper-plane me-2"></i>Book Appointment</h3>
              <form onSubmit={sendEmail}>
                <Row>
                  <Col sm={6}>
                    <Form.Group className='mb-2'>
                      <Form.Label htmlFor='name'>Name</Form.Label>
                      <Form.Control type="text" name="name" placeholder='Name' required />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='number'>Number</Form.Label>
                      <Form.Control type="tel" name="mobile" placeholder='Number' required />
                    </Form.Group>
                  </Col>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='email'>Email</Form.Label>
                      <Form.Control type="email" name="email" placeholder='example@gmail.com'  required/>
                  </Form.Group>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='email'>Date</Form.Label>
                      <Form.Control type="date" name="apdate" placeholder='Date' required />
                  </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='time'>Time</Form.Label>
                      <Form.Control type="time" name="time" placeholder='time' required />
                  </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                <Form.Label htmlFor='Treatment' className='ms-3'>Treatment</Form.Label>
                <Form.Select placeholder='Treatment' name="treatment" required>
                <option value="Crown and bridges">Crown and bridges</option>
                            <option value=" Cosmetic Dentistry"> Cosmetic Dentistry</option>
                            <option value=" Complete Gum Care"> Complete Gum Care</option>
                            <option value="Dental Implants">Dental Implants</option>
                            <option value="Orthodontics">Orthodontics</option>
                            <option value="Children’s Dentistry">Children’s Dentistry</option>
                            <option value="Tooth Extraction">Tooth Extraction</option>
                            <option value="Teeth Braces">Teeth Braces</option>
                            <option value="Dental Fillings">Dental Fillings</option>
                            <option value="Teeth Whitening">Teeth Whitening</option>
                            </Form.Select>
                </Form.Group>
                  </Col>
                  <Col sm={6}>
                  <Form.Group className='mb-2'>
                  <Form.Label htmlFor='doctor' className='ms-3'>Select Doctor</Form.Label>
                  <Form.Select name="doctor" id="doctor" required>
                    <option value="not selected">Select</option>
                    <option value="Dr. Priyank Bhandari">Dr. Priyank Bhandari</option>
                    <option value="Dr. Surpreet Bhandari">Dr. Surpreet Bhandari</option>
                  </Form.Select>
                  </Form.Group>
                  </Col>

    
                  <div className='d-flex justify-content-center'>
                  <button className='btn btn-warning submit-btn'>Submit</button>
                  </div>
                </Row>
                </form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
  
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NavbarMenu