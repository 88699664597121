import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Prostheis = () => {
  return (
    <div>
      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Prosthesis</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Prosthesis</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                    <h2>PROSTHESIS</h2>
                    <h3>CROWN AND BRIDGES</h3>
                    <p>A crown is a dental restoration that covers or ‘caps’ a tooth to restore it to its
normal shape, size and function. Its purpose is to strengthen or improve the
appearance of a tooth.
</p>
<div className='img-box'>
<img src="/servicesicons/prthesis.PNG" alt="" className='img-fluid' />
</div>
<p>A bridge is a dental restoration that replaces or ‘bridges’ the space where
one or more teeth have been lost. Bridge can be supported in many ways:
</p>
<p>Through natural teeth, Through implants
 and Through a combination of natural teeth and implants</p>
 <Row>
 <h3>DENTURES</h3>
    <Col xs={6}>
        
        <p className='denture'>Implant Retained Denture</p>
        <div className='img-box'>
<img src="/servicesicons/image-030.jpg" alt="" className='img-fluid' />
</div>
<div className='img-box'>
<img src="/servicesicons/image-032.jpg" alt=""className='img-fluid mt-3' />
</div>
<p className=' mt-4 denture'>Normal Complete Denture </p>
    </Col>
    <Col xs={6}>
        <p className='denture'>Metallic Denture</p>
        <div className='img-box'>
<img src="/servicesicons/image-031.jpg" alt="" className='img-fluid ' />
</div>
<div className='img-box'>
<img src="/servicesicons/image-033.jpg" alt="" className='img-fluid mt-3' />
</div>
<p className='denture'>Removable partial denture</p>
    </Col>
 </Row>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default Prostheis