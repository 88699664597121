import React from 'react'
import { Container } from 'react-bootstrap';
import './Testmonials.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Testimonial = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const reviews=[
    {id:1, name:"Jaya Mendiratta", word:"Excellent services, Dr. Priyank and Dr Surpreet both are very friendly and polite. Dr.Surpreet informed me about my health in a very clear manner and was very patient with my unending questions. Staff was also nice. Would highly recommend to everyone.",image:"https://lh3.googleusercontent.com/a-/ACNPEu-plhCVpXGdn809KVSd8j2jF1ka6SC0_fbU6CegSg=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:2, name:"Himanshu Verma", word:"4 years ago Thank you docs for giving back my smile Very professional and gentle. The staff is very accommodating and the office is very stunning and clean ",image:"https://lh3.googleusercontent.com/a-/ACNPEu-oC9l7_EavOoexfLlmemDo7_ROjBYV7ef-AnE9ndk=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:3, name:"Pradeep Singh", word:"Just as I wanted, a quick and easy check up with teeth cleaning. The staff are all really friendly and they made the process very easy",image:"https://lh3.googleusercontent.com/a-/ACNPEu8NyQ3aKi_aP8rwdryJR7JW2XhlFLw4HHfiXvEC_fc=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:4, name:"AAKASH INDORIA", word:"Excellent services, Dr. Priyank and Dr Surpreet both are very friendly and polite. Dr.Surpreet informed me about my health in a very clear manner and was very patient with my unending questions. Staff was also nice. Would highly recommend to everyone.",image:"/image/pp-e1591178425850.jpeg"},
    {id:5, name:"HIMANSHU VERMA", word:"My experience was truly second to none. Dr. Surpreet was incredibly helpful, thorough and informative. The staff is very accommodating and the office is very stunning and clean. Found my dentist for life.",image:"/image/323769_217484581665040_975211107_o-e1591178562743.jpg"},
    {id:6, name:"ANKUSH KUMAR", word:"I never knew visiting to a doctor could be a pleasant experience as well, I was consulted up to my utmost satisfaction, Thanks Delhi Dental Studio.",image:"/image/IMG_2417-scaled-e1591179094422.jpg"},
    {id:7, name:"Shivansh", word:"Both the doctors are highly skilled professionals with ample amount of experience which can be known via their understanding of the problem and the detailed explanation they provide after the analysis. My case was handled by Dr. Surpreet, she is very gentle in treating the teeth which is very rare to find. ",image:"https://lh3.googleusercontent.com/a/ALm5wu2H5c28H0XWTTV2gdZwbYEaT8lU21t-ImK0nddo=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:8, name:"Shubham Srivastava", word:"Superb clinic and superb doctor, takes care of the patient’s problem from very tiny to muscular issues in a similar way. Felt really satisfied after visiting this clinic, the only thing in a nutshell ‘Dental problems dealt right’.Highly recommended.",image:"https://lh3.googleusercontent.com/a-/ACNPEu9VnBuF0OfiUkmorVIZT3alOVax_ors3UjnMwzcqQ=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:9, name:"nasir mohd", word:"I have a perception of pain during RCT (root canal), but they treated so well, i dont even feel a pinch. Nice doctors and clinic as well. I truly recommend the clinic. Thank you so much for the treatment.",image:"https://lh3.googleusercontent.com/a/ALm5wu32fRNypCeBzfBc0KLfWG6EEiaZ_UbvAXXHdDrs=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:10, name:"Mahendra Mundru", word:"Doctor is kind, treats with precision, explains the treatments available and answers your questions with great detail. I strongly recommend",image:"https://lh3.googleusercontent.com/a-/ACNPEu_HBsr_27yHZP-YksfZ7k7W3LGFi8iDpO4LVWVlRA=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:11, name:"amandeep kaur kalra", word:"Great people who solves your mouth problems with ease. P.S. ,they will surely shut you up ,for sometime :p",image:"https://lh3.googleusercontent.com/a-/ACNPEu8yMDRSXSbdLzPu4mUAzav9YkzWJ9MfxgSZ4ZyL=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:12, name:"Arbab A", word:"Left with extremely sensitive teeth after a cleaning session. Doc was friendly but, eventually dealing with severe problems.",image:"https://lh3.googleusercontent.com/a-/ACNPEu8WuzZcdUunfZyMNQivs16p1utAA0wv9u1-zCihYQ=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:13, name:"Ankit Verma", word:"I never knew visiting to a doctor could be a pleasant experience as well,  I was consulted up to my utmost satisfaction,  Thanks Delhi Dental Studio.",image:"https://lh3.googleusercontent.com/a-/ACNPEu9qdMNZZOWaog-4IYTbyHGNDv8o67FSBT0rbNej2g=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:14, name:"Ashfaq Ansari", word:"painless, Quick, efficient and gentle treatment. thank you so much doctor.",image:"https://lh3.googleusercontent.com/a-/ACNPEu8scC0IcnaTZGOUZL4bR8TUy5gOO5aztkkuJ-SYoA=s40-c-c0x00000000-cc-rp-mo-br100"},
    {id:15, name:"Charu Manchanda", word:"Amazing services!! They are taking care of all safety measure. Keep up the great work.",image:"https://lh3.googleusercontent.com/a-/ACNPEu-yQa-jxA10YrCkHhE2PZxkfroylkE2sdY-aVWygQ=s40-c-c0x00000000-cc-rp-mo-br100"},
  ]
  return (
    <>
  <Container fluid className="review-container">
    <p className="text-center pt-5 mb-2 fw-bold text-danger">Testimonial</p>
    <h1 className="text-center">What They Say</h1>
<Carousel responsive={responsive}>
{reviews.map((index)=>{return(<>
<div className='review'>
  <div className="card speech-bubble">
      <div className="card-body ">
        <p className="card-text">{index.word}</p>
      </div>

  </div>
  <div className="d-flex">
  <img src={index.image} alt=""/>
  <p className='ms-2 name'>{index.name}</p>
  </div>
  </div>
  </>)})}
  
</Carousel>

{/* <div className='bx2'></div>
<div className='bx1'></div>
<div className='bx'></div> */}


  </Container>
    </>
 
  )
}

export default Testimonial