import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const Ortho = () => {
  return (
    <div>

      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Orthodontics</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Orthodontics</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                    <h2>ORTHODONTICS</h2>
                    <p>Comprehensive Orthodontic Treatment for Children, Teenagers and Adults</p>
                    <p>Advanced orthodontic and teeth straightening technology now offers a variety of
treatments for those wanting straighter teeth and faster results.</p>
<p>Our Orthodontic Services:</p>
<p>Braces (Metal, Ceramic)</p>
<div className='img-box'>
<img src="/image/ortho1.jpg" alt="" className='img-fluid' />
</div>

<h3>1. Who is an orthodontist?</h3>
<p>An orthodontist is a dental specialist trained in the diagnosis, treatment, and prevention of dental irregularities. They are specialized in correcting misaligned teeth and faulty bite.</p>
<h3>Which treatments are given by an orthodontist?</h3>
<p><strong>An Orthodontist provides a variety of treatments. These include:</strong></p>
<ul>
  <li> Aligning the tips of the teeth</li>
  <li>Helps improve the health of your gums</li>
  <li>Straightening crooked teeth</li>
  <li>Preventing wear and tear of the teeth</li>
  <li>Closing wide gaps between the teeth</li>
</ul>
<div className='img-box'>
<img src="/image/ortho.jpg" alt="" className='img-fluid' />
</div>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default Ortho