import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Pages/Home";
import Contactus from "./Pages/Contactus";
import Gallery from "./Pages/Gallery";
import Services from "./Pages/Services";
import About from "./Pages/About";
import ScrollToTop from './components/ScrollToTop';
import FloatingWhatsApp from 'react-floating-whatsapp';
import General from "./Pages/Items/General";
import Cosmetics from "./Pages/Items/Cosmetics";
import Dentalimp from "./Pages/Items/Dentalimp";
import Gumstreat from "./Pages/Items/Gumstreat";
import Ortho from "./Pages/Items/Ortho";
import Maxillo from "./Pages/Items/Maxillo";
import DentalRadio from "./Pages/Items/DentalRadio";
import Prostheis from "./Pages/Items/Prostheis";
import Endodontic from "./Pages/Items/Endodontic";
import Jaw from "./Pages/Items/Jaw";
import Stronging from "./Pages/Items/Stronging";
import Kids from "./Pages/Items/Kids";
import SmileGallery from "./Pages/SmileGallery";
import Notfound from "./Pages/Notfound";
function App() {
  return (
    
    <BrowserRouter>
    
      <div>
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/smile-gallery" element={<SmileGallery />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/general-dentistry" element={<General />} />
          <Route path="/cosmetic-dentistry" element={<Cosmetics />} />
          <Route path="/dental-implants" element={<Dentalimp />} />
          <Route path="/gums-treatment" element={<Gumstreat />} />
          <Route path="/orthodontics" element={<Ortho />} />
          <Route path="/maxillofacial-surgery" element={<Maxillo />} />
          <Route path="/dental-radiography" element={<DentalRadio />} />
          <Route path="/prosthesis" element={<Prostheis />} />
          <Route path="/endodontic-treatment" element={<Endodontic />} />
          <Route path="/jaw-pain-and-headache" element={<Jaw />} />
          <Route path="/snoring-and-sleep-apnoea" element={<Stronging />} />
          <Route path="/children-dentistry" element={<Kids />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </div>
      <FloatingWhatsApp 
        phoneNumber="+918860650689"
        accountName="Delhi Dental Studio"
        allowClickAway
        notification
        avatar="/image/logo.png"
        notificationDelay={60000} // 1 minute
       
      />
    </BrowserRouter>
  )
}

export default App;
