import React from 'react'
import Header from '../../components/Header/Header';
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import "./items.css";
import ItemsMenu from './ItemsMenu';
const DentalRadio = () => {
  return (
    <div>

      <Header />
      <NavbarMenu />
      <ul class="centermiddledown breadcrumbs">
        <div class="bgground">
          <li><Link class="fas fa-home" to="/"></Link></li>
          <li><Link  to="/">Services</Link></li>
          <li>Dental Radiography</li>
        </div>
      </ul>
    <div className='heading text-center'>
      <h1>Dental Radiography</h1>
    </div>
        <Container className='item-container'>
            <Row>
                <Col md={8}>
                    <h2>DENTAL RADIOGRAPHY</h2>
                    <h3>Digital RVG</h3>
                    <p>Digital radiography is a form of X-ray imaging, where digital X-ray sensors are
used instead of traditional photographic film.</p>
<p>Advantages</p>
<ul>
    <li>Less Radiation</li>
    <li>Shorter Dental Appointments
</li>
    <li>Higher Quality
</li>
    <li>Transferring Dental Records
</li>
    <li>Environmentally Friendly</li>
    <li>The Complete Picture</li>
</ul>
<div className='img-box'>
<img src="/servicesicons/image-027.jpg" alt="" className='img-fluid' />
</div>
                </Col>
                <Col md={4}>
                <ItemsMenu />
                </Col>
            </Row>
        </Container>
    <Footer />
    </div>
  )
}

export default DentalRadio