export const photos = [
    
  {
    src: "https://lh3.googleusercontent.com/p/AF1QipOMrW-E2VII4Pd9Gv22RXLmlFbjZkaZWx3M5UaJ=s2732-w2732-h1312",
    width: 3,
    height: 4
  },
  {
    src: "/image/IMG_0130.jpg",
    width: 3,
    height: 3
  },
  {
    src: "/image/IMG_0131.jpg",
    width: 3,
    height: 4
  },
  {
    src: "https://lh3.googleusercontent.com/p/AF1QipPRuy6UxOKCDjIMnyY-_o2sNuua3YHrcoIcfrBb=s2732-w2732-h1312",
    width: 3,
    height: 4
  },
  {
    src: "https://lh3.googleusercontent.com/p/AF1QipPYGHqP8qZmaZCd5uMPa3Zs92ISNE6DcFtGBS-y=s2732-w2732-h1312",
    width: 3,
    height: 3
  },
  
  {
    src: "/image/post.JPEG",
    width: 4,
    height: 3
  },


  
  {
    src: "/image/2.PNG",
    width: 3,
    height: 4
  },
  {
      src: "https://lh3.googleusercontent.com/p/AF1QipOFV2p7192wUiuZPburfAOnG3sLbyCSzqW4N_TY=s2732-w2732-h1312",
      width: 3,
      height: 4
    },
    {
      src: "https://lh3.googleusercontent.com/p/AF1QipP_khq-ZNpo53Dste5Fb3Ffs0R67CkM46-lrpCt=s2732-w2732-h1312",
      width: 3,
      height: 4
    },
    {
      src: "https://lh3.googleusercontent.com/p/AF1QipPRuy6UxOKCDjIMnyY-_o2sNuua3YHrcoIcfrBb=s2732-w2732-h1312",
      width: 3,
      height: 4
    },
    {
      src: "https://lh3.googleusercontent.com/p/AF1QipORlXBsjCySqcUJOP6aK4C21310ffyV53Vy8ThH=s2732-w2732-h1312",
      width: 3,
      height: 3
    },
    {
      src: "/image/delhidental.jpg",
      width: 3,
      height: 3
    },
     {
      src: "https://lh3.googleusercontent.com/p/AF1QipPn3cohM0kDaXvRajr8pwY1tz-BBWJp9v4G2XmU=s2732-w2732-h1312",
      width: 4,
      height: 3
    },
    {
      src: "https://lh3.googleusercontent.com/p/AF1QipPFS5kPWv04WCRFADI_iaturMMwU8YQAhMVclec=s2732-w2732-h1312",
      width: 3,
      height: 3
    },
    {
      src: "/image/2.PNG",
      width: 3,
      height: 4
    },
    {
      src: "https://lh3.googleusercontent.com/p/AF1QipNPshkIwZNiaiRgU9yg8_CWH0k5IefJzQgRs1gp=s2732-w2732-h1312",
      width: 3,
      height: 4
    },
  ];