import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import './Header.css'
const Header = () => {
  return (
    <div>
      <Navbar className="header">
        <Container fluid>
        <Navbar.Brand href="tel:+918860650689"><i className="fas fa-phone-square-alt me-2"></i>+91 8860650689</Navbar.Brand>
          <Nav className='ms-auto'>
          <Nav.Link href="https://facebook.com/delhidentalstudio" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></Nav.Link>
          <Nav.Link href="https://www.instagram.com/delhi_dental_studio/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></Nav.Link>
          {/* <Nav.Link href="https://twitter.com/delhidentalstudio" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></Nav.Link>
          <Nav.Link href="https://youtube.com/delhidentalstudio" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></Nav.Link> */}
          </Nav>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header